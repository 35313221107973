<template>
  <div class="bg bglogin header-app">
    <div class="bg__container">
      <div>
        <img :src="imageBg" alt="Background" class="bg__image" />
      </div>
      <div>
        <co-card :simpleCard="true" class="bg__form header-app">
          <template #body>
            <div class="bg__language-select-container co">
              <co-translator
                :height="110"
                :t="$t"
                class="bg__select select-trans"
                id="languageSelect"
                v-model:modelValue="selectedLanguage"
                :items="optionLanguage"
                @update:modelValue="($event: string) => onChangeLanguage($event)"
              />
            </div>
            <div class="bg__container-form">
              <header class="form__logo">
                <img :src="imageLogo" alt="logo" class="bg__logo" />
              </header>
              <div class="form__container">
                <h1 class="form__container-title">
                  {{ $t("login.common.welcome") }}!
                </h1>
                <section class="form__container-inputs">
                  <co-input
                    v-model:modelValue="form.user"
                    class="custom-style-input"
                    :label="$t('login.common.user')"
                    size="md"
                    :error="errors.user"
                    :required="true"
                    @keyup.enter="logIn"
                    block
                  />
                  <co-input
                    v-model:modelValue="form.password"
                    class="custom-style-input"
                    :label="$t('login.common.password')"
                    size="md"
                    type="password"
                    :error="errors.password"
                    :completed="isPasswordInputCompleted"
                    :required="true"
                    @keyup.enter="logIn"
                    block
                  />
                </section>
                <section class="form__container-remember-me">
                  <co-checkbox
                    v-model:value="form.remember"
                    class="custom-style-checkbox"
                    name="check1"
                    :checked="true"
                    size="sm"
                    :label="$t('login.common.rememberMe')"
                    status="select"
                    type="primary"
                  />
                  <p
                    @click="recoverPassword"
                    class="form__container-forgot-password"
                  >
                    {{ $t("login.common.forgotPassword") }}
                  </p>
                </section>

                <section>
                  <co-button
                    size="sm"
                    type="primary"
                    :contained="true"
                    @trigger-click="logIn"
                    block
                  >
                    <span>{{ $t("login.common.signIn") }}</span>
                    <em class="mdi mdi-check"></em>
                  </co-button>
                </section>
                <section class="form__container_info">
                  <p class="form__container_info-help">
                    {{ $t("login.common.needHelp") }}
                  </p>
                  <p class="form__container_info-contact-us">
                    {{ $t("login.common.contactUs") }}
                  </p>
                </section>
              </div>
            </div>
          </template>
        </co-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { useCommonStore } from "@/stores/common";
import { validations } from "koffee-senser-components";
import LoginApi from "@/api/LoginApi";
import cookie from "@/helpers/cookies";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useLanguageStore } from "@/stores/language";
import { useRouter } from "vue-router";

/* eslint-disable */

export default defineComponent({
  name: "LoginComponent",
  data() {
    return {
      imageBg: process.env.VUE_APP_BACKGROUND_LOGIN,
      imageLogo: process.env.VUE_APP_LOGO_LOGIN,
      defaultLang: "es",
      completed: false,
      errors: {
        user: false,
        password: false,
      },
      form: {
        user: "",
        password: "",
        remember: false,
      },
      optionLanguage: ["es", "en"],
      selectedLanguage: "",
    };
  },
  setup() {
    const common = useCommonStore();
    const toast: any = inject("toast");
    const languageStore = useLanguageStore();
    return { common, toast, languageStore };
  },
  computed: {
    isUserInputCompleted(): boolean {
      return validations.email.test(this.form.user);
    },
    isPasswordInputCompleted(): boolean {
      return this.form.password !== "";
    },
  },
  mounted() {
    const language = Cookies.get("language") ?? this.defaultLang;
    this.selectedLanguage = language;
  },
  methods: {
    recoverPassword() {
      this.common.changeState("showModalRecoverPassword", true);
    },
    validation() {
      this.errors.user = false;
      this.errors.password = false;
      if (this.form.user == "" && this.form.password == "") {
        this.errors.user = true;
        this.errors.password = true;
        this.toast({
          type: "error",
          title: this.$t("login.common.sorry"),
          text: this.$t("login.common.completeFields"),
          size: "sm",
        });
        return;
      }
      if (this.form.user == "") {
        this.toast({
          type: "error",
          title: this.$t("login.common.sorry"),
          text: this.$t("login.common.userRequired"),
          size: "sm",
        });
        this.errors.user = true;
        this.errors.password = false;
        return;
      }
      if (this.form.password == "") {
        this.toast({
          type: "error",
          title: this.$t("login.common.sorry"),
          text: this.$t("login.common.passwordRequired"),
          size: "sm",
        });
        this.errors.user = false;
        this.errors.password = true;
        return;
      }
      return this.errors.user === false && this.errors.password === false;
    },
    async logIn() {
      const validation = this.validation();
      if (validation) {
        const username = this.form.user;
        const password = this.form.password;

        if (this.form.remember) {
          const credentials = {
            user: this.form.user,
            password: this.form.password,
          };
          // Encripta las credenciales utilizando una clave secreta
          const secretKey = process.env.VUE_APP_SECRET;
          const encryptedCredentials = CryptoJS.AES.encrypt(
            JSON.stringify(credentials),
            secretKey
          ).toString();

          // Almacena las credenciales encriptadas en una cookie
          Cookies.set("encryptedCredentials", encryptedCredentials, {
            expires: 7,
          });
        } else {
          // Si el checkbox "Recuérdame" no está marcado, elimina la cookie si existe
          Cookies.remove("encryptedCredentials");
        }
        await LoginApi.login(username, password)
          .then((resp: any) => {
            cookie.setCookie("access", resp.data?.access);
            LoginApi.getSuiteApps().then((response) => {
              const permissions = JSON.stringify(resp.data?.permissions);
              cookie.setStorage("permissions", permissions);
              cookie.setCookie("refresh", resp.data?.refresh);
              cookie.setCookie("origin", process.env.VUE_APP_URL_ORIGIN);
              const codeStyle = resp.data?.permissions?.tenant_app?.style_code;
              cookie.setCookie("styles", codeStyle);
              cookie.setCookie("date_format", resp.data.date_format);
              if (resp?.data.permissions?.user_status_code === "new_state") {
                const token = resp.data?.access;
                const tokenEncode = btoa(token);
                const url = location.origin.includes("local")
                  ? "/login"
                  : `${process.env.VUE_APP_SUITE_URL}/login`;
                location.href = url.concat(
                  "/redirect-token?token=",
                  tokenEncode
                );
              } else {
                const suiteApps: any[] = response.data.app_suite;
                suiteApps.push(window.location.origin);
                const originLocal =
                  process.env.VUE_APP_URL_ORIGIN || window.location.origin;
                let next = suiteApps
                  .map((app) => {
                    if (app.origin_url !== originLocal) {
                      return app.origin_url;
                    }
                  })
                  .join(",");
                LoginApi.shareTokend(
                  next.split(",")[0],
                  resp.data?.access || "",
                  next,
                  window.location.origin
                );
              }
            });
          })
          .catch(() => {
            this.toast({
              type: "error",
              title: this.$t("login.common.sorry"),
              text: this.$t("login.common.dataIsNotCorrect"),
              size: "sm",
            });
          });
      }
    },
    onChangeLanguage(value: string) {
      Cookies.set("language", value);
      const language = Cookies.get("language") ?? this.defaultLang;
      this.languageStore.changeLanguage(language);
    },
  },
  created() {
    const router = useRouter();
    const hasAccess = Cookies.get("suite_admin_access");
    if (hasAccess) return router.push("/app");
    LoginApi.getUserIp();

    // Recupera las credenciales encriptadas de la cookie
    const encryptedCredentials = Cookies.get("encryptedCredentials");

    if (encryptedCredentials) {
      // Desencripta las credenciales utilizando la misma clave secreta
      const secretKey = process.env.VUE_APP_SECRET;
      const decryptedCredentials = CryptoJS.AES.decrypt(
        encryptedCredentials,
        secretKey
      ).toString(CryptoJS.enc.Utf8);

      // Analiza las credenciales desencriptadas
      try {
        const { user, password } = JSON.parse(decryptedCredentials);
        this.form.user = user;
        this.form.password = password;
        this.form.remember = true;
      } catch (error) {
        // Si no se pudieron analizar las credenciales, elimina la cookie
        Cookies.remove("encryptedCredentials");
      }
    }
  },
});
</script>
<style lang="scss" scoped>
body {
  overflow: hidden;
}
.custom-style-input {
  margin-top: 0.5em;
}

:deep(.custom-style-checkbox .label-checkbox) {
  font-weight: 400;
  font-size: 14px;
  color: #60647d;
}
</style>
