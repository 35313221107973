import common from "./common";

export default {
  es: {
    ...common.es,
  },
  en: {
    ...common.en,
  },
};
