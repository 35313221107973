<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLanguageStore } from "@/stores/language";
import "@/helpers/events";
import Cookies from "js-cookie";

export default defineComponent({
  name: "LoginPage",
  setup() {
    const languageStore = useLanguageStore();
    const { locale } = useI18n();

    watch(
      () => languageStore.language.locale,
      (newLocale) => {
        locale.value = newLocale;
      },
      { immediate: true }
    );

    return { languageStore };
  },
  methods: {
    togglePermissions() {
      const language = Cookies.get("language") ?? "es";
      this.languageStore.changeLanguage(language);
    },
  },
  mounted() {
    this.togglePermissions();
  },
});
</script>
