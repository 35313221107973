<template>
  <section></section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoginApi from "@/api/LoginApi";
import cookie from "@/helpers/cookies";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "RedirectTokenView",
  setup() {
    const route = useRoute();
    return { route };
  },
  methods: {
    async getPermissionsByUser() {
      const token = this.route.query?.token || "";
      let next: string = this.route.query?.next?.toString() || "";
      const origin: string = this.route.query?.origin?.toString() || "";
      const url = this.route.query?.url || "";
      const tokenDecode = atob(token.toString());
      cookie.setCookie("access", tokenDecode);
      cookie.setCookie("refresh", tokenDecode);
      if (next) {
        const nextSp = next.split(",");
        next = nextSp.length > 1 ? next : "";
        LoginApi.shareTokend(nextSp[0], tokenDecode, next, origin);
        return false;
      }
      if (origin) {
        location.href = origin;
        return false;
      }
      location.href = url !== "" ? url.toString() : "/app";
    },
  },
  created() {
    LoginApi.getUserIp().then(() => {
      this.getPermissionsByUser();
    });
  },
});
</script>
