import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import RestorePasswordView from "@/views/ResetPasswordView.vue";
import RedirectTokenView from "@/views/RedirectTokenView.vue";
import RedirectView from "@/views/RedirectView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/login/reset-password",
    name: "ResetPassword",
    component: RestorePasswordView,
  },
  {
    path: "/login/redirect-token",
    name: "Token",
    component: RedirectTokenView,
  },
  {
    path: "/login/redirect",
    name: "Redirect",
    component: RedirectView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
