export default {
  common: {
    welcome: "Bienvenido",
    user: "Usuario",
    password: "Contraseña",
    rememberMe: "Acuérdate de mí",
    forgotPassword: "Contraseña olvidada",
    signIn: "Iniciar sesión",
    needHelp: "¿Necesita ayuda?",
    contactUs: "Contacte con nosotros",
    resetPassword: "Restablecer contraseña",
    enterYourRegisteredEmail:
      "Introduce tu correo electrónico registrado para que podamos enviarte el enlace para cambiar tu contraseña.",
    enterYourEmailHere: "Introduzca aquí su dirección de correo electrónico",
    changePassword: "Cambiar contraseña",
    finishedLogin:
      "Está a punto de cambiar su contraseña, cuando termine debe iniciar sesión con sus nuevos datos.",
    newPassword: "Nueva contraseña",
    newConfirmPassword: "Confirmar nueva contraseña",
    sorry: "¡Lo siento!",
    completeFields: "Debe rellenar los siguientes campos para iniciar sesión",
    userRequired: "El usuario es requerido",
    passwordRequired: "La contraseña es requerido",
    dataIsNotCorrect: "Los datos introducidos no son correctos",
    tokenError: "Te falta el token intenta entrar de nuevo desde el enlace.",
    completePassword: "Debe rellenar el campo de la contraseña",
    completeConfirmPassword: "Debe rellenar el campo confirmar contraseña",
    passwordsDoNotMatch: "Las contraseñas no coinciden",
    successfulProcess: "¡Éxito del proceso!",
    passwordSuccess: "Su contraseña ha sido cambiada con éxito.",
    errorProcess: "¡Error de proceso!",
    passwordError: "Su contraseña no ha sido modificada.",
    completeEmail: "Debe rellenar el campo de correo electrónico",
    emailSent: "¡Email enviado!",
    checkYourEmail:
      "Por favor, compruebe su correo electrónico, hemos enviado el mensaje.",
  },
};
