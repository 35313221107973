import en from "./en";
import es from "./es";

export default {
  es: {
    ...es,
  },
  en: {
    ...en,
  },
};
