<template>
  <LoginComponent />
  <RestorePasswordModal />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoginComponent from "@/components/LoginComponent.vue";
import RestorePasswordModal from "@/components/modals/RestorePasswordModal.vue";

export default defineComponent({
  name: "LoginView",
  components: {
    LoginComponent,
    RestorePasswordModal,
  },
});
</script>
