import { defineStore } from "pinia";

export const useCommonStore = defineStore("common", {
  state: () => ({
    modals: {
      showModalRecoverPassword: false,
    },
  }),
  actions: {
    changeState(field: string, value: boolean) {
      const obj: any = this.modals;
      return (obj[field as keyof any] = value);
    },
  },
});
