import Cookies from "js-cookie";

export default {
  setCookie(name: string, value: string, options: any = {}) {
    const cookieBase = process.env.VUE_APP_BASE_COOCKIE;
    const cookieName = `${cookieBase}_${name}`;
    Cookies.set(cookieName, value, { expires: 1, ...options });
  },
  setStorage(name: string, value: string) {
    const StorageBase = process.env.VUE_APP_BASE_COOCKIE;
    const StorageName = `${StorageBase}_${name}`;
    localStorage.setItem(StorageName, value);
  },

  getStorage(name: string) {
    const StorageBase = process.env.VUE_APP_BASE_COOCKIE;
    const StorageName = `${StorageBase}_${name}`;
    return localStorage.getItem(StorageName);
  },
};
