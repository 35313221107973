import axios from "axios";
import Cookies from "js-cookie";
import cookie from "@/helpers/cookies";

const base_URL = process.env.VUE_APP_URL_BASE;

const suiteCenterApi = axios.create({
  baseURL: base_URL,
});

suiteCenterApi.interceptors.request.use((config: any) => {
  const ip: string | null = cookie.getStorage("publicIp");

  if (config.headers !== undefined) {
    const token = Cookies.get("suite_admin_access");
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["PublicIp"] = ip;
    config.headers["OriginTest"] = process.env.VUE_APP_URL_ORIGIN;
  }
  return config;
});

export default suiteCenterApi;
