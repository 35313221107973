export default {
  common: {
    welcome: "Welcome",
    user: "User",
    password: "Password",
    rememberMe: "Remember me",
    forgotPassword: "Forgot password",
    signIn: "Sign in",
    needHelp: "¿Need help?",
    contactUs: "Contact us",
    resetPassword: "Reset password",
    enterYourRegisteredEmail:
      "Enter your registered email so we can send you the link to change your password.",
    enterYourEmailHere: "Enter your email here",
    changePassword: "Change Password",
    finishedLogin:
      "You are about to change your password, when finished you must login with your new data.",
    newPassword: "New password",
    newConfirmPassword: "Confirm new password",
    sorry: "¡Sorry!",
    completeFields: "You must complete the fileds to log in",
    userRequired: "The user is required",
    passwordRequired: "Password is required",
    dataIsNotCorrect: "The data entered is not correct",
    tokenError: "You are missing the token try to enter from the link again.",
    completePassword: "You must complete the password field",
    completeConfirmPassword: "You must complete the confirm password field",
    passwordsDoNotMatch: "Passwords do not match",
    successfulProcess: "Successful process!",
    passwordSuccess: "Your password has been successfully changed.",
    errorProcess: "Error process!",
    passwordError: "Your password has not been changed.",
    completeEmail: "You must complete the email field",
    emailSent: "Email sent!",
    checkYourEmail: "Please check your email, we have sent the message.",
  },
};
