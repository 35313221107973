<template>
  <ResetPasswordComponent></ResetPasswordComponent>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ResetPasswordComponent from "@/components/ResetPasswordComponent.vue";
export default defineComponent({
  name: "ResetPasswordView",
  components: {
    ResetPasswordComponent,
  },
});
</script>
