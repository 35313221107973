<template>
  <co-modal v-model:value="modal" size="md" :width="406" @close="close">
    <template #title>
      <h1 class="title">{{ $t("login.common.resetPassword") }}</h1>
    </template>
    <template #body>
      <p class="text-content">
        {{ $t("login.common.enterYourRegisteredEmail") }}
      </p>
      <co-input
        v-model:modelValue="email"
        class="custom-style-input"
        label=""
        :placeholder="$t('login.common.enterYourEmailHere')"
        size="md"
        block
        :error="errorEmail"
      />
    </template>
    <template #footer>
      <co-button size="sm" type="primary" contained @trigger-click="sendToken">
        <span>{{ $t("login.common.changePassword") }}</span>
        <em class="mdi mdi-chevron-right"></em>
      </co-button>
    </template>
  </co-modal>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { useCommonStore } from "@/stores/common";
import LoginApi from "@/api/LoginApi";
/* eslint-disable */

export default defineComponent({
  name: "RestorePasswordModal",
  data() {
    return {
      email: "",
      errorEmail: false,
    };
  },
  setup() {
    const common = useCommonStore();
    const toast: any = inject("toast");
    return { common, toast };
  },
  computed: {
    modal: {
      get(): boolean {
        return this.common.modals.showModalRecoverPassword;
      },
      set(v: boolean) {
        this.common.changeState("showModalRecoverPassword", v);
      },
    },
  },
  methods: {
    close() {
      this.errorEmail = false;
    },
    validateForm() {
      if (this.email === "") {
        this.errorEmail = true;
        this.toast({
          type: "error",
          title: this.$t("login.common.sorry"),
          text: this.$t("login.common.completeEmail"),
          size: "sm",
        });
        return;
      } else {
        this.errorEmail = false;
      }
      return this.email !== "";
    },
    sendToken() {
      const validateForm = this.validateForm();
      if (validateForm) {
        LoginApi.resetPassword(this.email).then(() => {
          this.toast({
            type: "info",
            title: this.$t("login.common.emailSent"),
            text: this.$t("login.common.checkYourEmail"),
            size: "sm",
          });
          this.email = "";
          this.common.changeState("showModalRecoverPassword", false);
        });
      }
    },
  },
});
</script>
