import suiteCenterApi from "@/helpers/axios";
class LoginApi {
  getUserIp() {
    /*const URL_PUBLIC_IP = process.env?.VUE_APP_URL_PUBLIC_IP;
    return axios.get(`${URL_PUBLIC_IP}`).then((resp: any) => {
      cookie.setStorage("publicIp", resp?.data);
    });*/
    return Promise.resolve("");
  }

  login(email: string, password: string) {
    return suiteCenterApi.post("/api/auth/login/", {
      identification: email,
      password,
    });
  }
  resetPassword(email: string) {
    return suiteCenterApi.post("/api/auth/password_reset/", {
      email,
    });
  }
  confirm_password(data: object) {
    return suiteCenterApi.post("/api/auth/confirm_password/", {
      ...data,
    });
  }
  getPermissionTree() {
    return suiteCenterApi.get(
      "/api/auth/permission_tree/?only_permissions=true"
    );
  }
  getSuiteApps() {
    // return suiteCenterApi.get("/api/auth/list_app/");
    return Promise.resolve({ data: { app_suite: [] } });
  }
  shareTokend(url: string, token: string, next: string, origin: string) {
    next = next.replace(url + ",", "");
    const tokenEncode = btoa(token);
    const urlApp = url.concat(
      "/login/redirect-token?token=",
      tokenEncode,
      "&autoclosed=true",
      "&next=",
      next,
      "&origin=",
      origin
    );
    window.open(urlApp, "_self", "width=1,height=1,left=-1000,top=-1000");
  }
}
export default new LoginApi();
