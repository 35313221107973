import { createI18n } from "vue-i18n";
import lang from ".";
import { langs } from "koffee-senser-components";

const messages = {
  en: {
    login: {
      ...lang.en,
    },
    components: {
      ...langs.en,
    },
  },
  es: {
    login: {
      ...lang.es,
    },
    components: {
      ...langs.es,
    },
  },
};

const i18n = createI18n<any>({
  legacy: false,
  locale: "es", // Idioma predeterminado
  messages,
});

export default i18n;
