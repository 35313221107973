<template>
  <section></section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoginApi from "@/api/LoginApi";
import cookie from "@/helpers/cookies";
import Cookies from "js-cookie";

export default defineComponent({
  name: "RedirectView",
  methods: {
    async getPermissionsByUser() {
      await LoginApi.getPermissionTree()
        .then((resp) => {
          const permissions = JSON.stringify(resp.data?.permissions);
          cookie.setStorage("permissions", permissions);
          cookie.setCookie("date_format", resp.data.permissions.date_format);
          location.href = "/app";
        })
        .catch((err) => {
          if ([401, 403].includes(err.response.status)) {
            Cookies.remove("suite_admin_refresh");
            Cookies.remove("suite_admin_access");
            localStorage.removeItem("suite_admin_permissions");
          }
          location.href = "/login";
        });
    },
  },
  created() {
    LoginApi.getUserIp().then(() => {
      this.getPermissionsByUser();
    });
  },
});
</script>
