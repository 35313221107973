<template>
  <div class="reset-password-container">
    <div class="reset-container">
      <img class="reset-container__image" :src="imageBg" alt="image" />
      <co-card class="reset-container__card" @close="close">
        <template #title>
          <h2 class="reset-container__title">
            {{ $t("login.common.resetPassword") }}
          </h2>
        </template>
        <template #body>
          <p class="reset-container__body">
            {{ $t("login.common.finishedLogin") }}
          </p>
          <div class="reset-container__form-container">
            <co-input
              v-model:modelValue="password"
              :label="$t('login.common.newPassword')"
              :placeholder="$t('login.common.newPassword')"
              size="md"
              type="password"
              block
              :error="errorPassword"
            />
            <co-input
              v-model:modelValue="confirmPassword"
              :label="$t('login.common.newConfirmPassword')"
              :placeholder="$t('login.common.newConfirmPassword')"
              size="md"
              type="password"
              :error="validatePasword"
              block
            />
          </div>
        </template>
        <template #footer>
          <div class="reset-container__button">
            <co-button
              size="sm"
              type="primary"
              contained
              @trigger-click="resetPassword"
            >
              <span>{{ $t("login.common.resetPassword") }}</span>
              <em class="mdi mdi-check"></em>
            </co-button>
          </div>
        </template>
      </co-card>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import LoginApi from "@/api/LoginApi";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "ResetPasswordComponent",
  data() {
    return {
      token: "",
      password: "",
      confirmPassword: "",
      imageBg: process.env.VUE_APP_RECOVER_PASSWORD_IMAGE,
      errorPassword: false,
      errorConfirmPassword: false,
    };
  },
  setup() {
    const toast: any = inject("toast");
    const router = useRouter();
    const route = useRoute();
    return { toast, router, route };
  },
  computed: {
    validatePasword(): boolean {
      return (
        this.confirmPassword !== this.password || this.errorConfirmPassword
      );
    },
  },
  methods: {
    validateForm() {
      this.errorPassword = false;
      this.errorConfirmPassword = false;
      if (this.token === "") {
        this.toast({
          type: "error",
          title: this.$t("login.common.sorry"),
          text: this.$t("login.common.tokenError"),
          size: "sm",
        });
      }
      if (this.password === "" && this.confirmPassword === "") {
        this.errorPassword = true;
        this.errorConfirmPassword = true;
        this.toast({
          type: "error",
          title: this.$t("login.common.sorry"),
          text: this.$t("login.common.completeField"),
          size: "sm",
        });
        return;
      }
      if (this.password === "") {
        this.errorPassword = true;
        this.toast({
          type: "error",
          title: this.$t("login.common.sorry"),
          text: this.$t("login.common.completePassword"),
          size: "sm",
        });
        return;
      }
      if (this.confirmPassword === "") {
        this.errorConfirmPassword = true;
        this.toast({
          type: "error",
          title: this.$t("login.common.sorry"),
          text: this.$t("login.common.completeConfirmPassword"),
          size: "sm",
        });
        return;
      }
      if (this.confirmPassword !== this.password) {
        this.errorConfirmPassword = true;
        this.toast({
          type: "error",
          title: this.$t("login.common.sorry"),
          text: this.$t("login.common.passwordDoNotMatch"),
          size: "sm",
        });
        return;
      }
      return (
        this.errorPassword === false &&
        this.errorConfirmPassword === false &&
        this.token !== ""
      );
    },
    resetPassword() {
      const data = {
        token: this.token,
        password: this.password,
        conf_password: this.confirmPassword,
      };
      const validateForm = this.validateForm();
      if (validateForm) {
        LoginApi.confirm_password(data)
          .then(() => {
            this.toast({
              type: "success",
              title: this.$t("login.common.successfulProcess"),
              text: this.$t("login.common.passwordSuccess"),
              size: "sm",
            });
            this.close();
          })
          .catch(() => {
            this.toast({
              type: "error",
              title: this.$t("login.common.errorProcess"),
              text: this.$t("login.common.passwordError"),
              size: "sm",
            });
          });
      }
    },
    getToken() {
      const fullPath = this.route.fullPath;
      if (fullPath.includes("?") && fullPath.split("?").length > 0) {
        const fullPathSplit = fullPath.split("?")[1];
        this.token = fullPathSplit.split("=")[1];
      }
    },
    close() {
      this.password = "";
      this.confirmPassword = "";
      this.errorPassword = false;
      this.errorConfirmPassword = false;
      this.router.push("/login");
    },
  },
  created() {
    this.getToken();
    LoginApi.getUserIp();
  },
});
</script>
